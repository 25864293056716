import { getMapsHelper } from './util/mapsHelper';

const homeMap = {
	theMap: document.querySelector('#home_map'),
	init: async function() {
		const mapsHelper = getMapsHelper();

		await mapsHelper.ready();

		mapsHelper.createMap({
			element: this.theMap,
			locationElementSelector: '.home_fac',
			useRichmarker: true,
			markerReducer: el => {
				return {
					lat: el.getAttribute('lat'),
					lng: el.getAttribute('lng'),
					content: `<a href="${el.getAttribute('link')}" class="map_pin"><img src="/dist/images/icons/ic_map_pin.svg" alt="" /><span>${el.getAttribute('count')}</span></a>`
				};
			}
		});
	}
};

export const initHomeMap = () => homeMap.init();