const faqsTabs = {
	buttons: document.querySelectorAll('#faq_list .faq_q'),
	// tabs: document.querySelectorAll('.internal_tab'),
	btnClick: function(e) {
		const clickedButton = e.target.closest('.faq_q');

		if(clickedButton) {
			this.buttons.forEach(button => button.removeAttribute('data-active'));
			clickedButton.setAttribute('data-active', true);

			/*
			this.tabs.forEach(tab => {
				tab.removeAttribute('data-active');
				if(tab.getAttribute('data-tab') === clickedButton.getAttribute('data-tab')) {
					tab.setAttribute('data-active', true);
				}
			});
			*/
		}
	},
	init: function() {
		document.querySelector('#faq_list').addEventListener('click', e => this.btnClick(e));
	}
};

export const initFaqsTabs = () => faqsTabs.init();