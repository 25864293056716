import { getMapsHelper } from './util/mapsHelper';

const contactMap= {
	theMap: document.querySelector('#contact_map'),
	init: async function() {
		const mapsHelper = getMapsHelper();

		await mapsHelper.ready();

		mapsHelper.createMap({
			element: this.theMap,
			locationElementSelector: '.contact_fac',
			useRichmarker: true,
			markerReducer: el => {
				return {
					lat: el.getAttribute('lat'),
					lng: el.getAttribute('lng'),
					content: `
						<a href="${el.getAttribute('link')}" class="map_pin">
							<span>${el.getAttribute('num')}</span>
							<img src="/dist/images/icons/ic_map_pin.svg" alt="" />
						</a>`
				};
			}
		});
	}
};

export const initMultiMap = () => contactMap.init();