/* global google */
import { AutomatitCarousel } from './automatit_carousel.m';
import { getMapsHelper } from './util/mapsHelper';

const facilityTop = {
	mobileToggle: document.querySelector('#toggle_fac_info'),
	info: document.querySelector('#facility_top'),
	buttons: document.querySelectorAll('#facility_toggle_buttons button'),
	tabs: document.querySelectorAll('.facility_top_tab'),
	tabClick: function(e) {
		const clickedButton = e.target.closest('button');

		if(clickedButton) {
			this.buttons.forEach(button => button.removeAttribute('active'));
			clickedButton.setAttribute('active', true);

			this.tabs.forEach(tab => {
				tab.removeAttribute('active');
				if(tab.getAttribute('tab') === clickedButton.getAttribute('tab')) {
					tab.setAttribute('active', true);
				}
			});
		}
	},
	mobileClick: function() {
		this.mobileToggle.switchAttribute('state', 'closed', 'open');
		this.info.switchAttribute('state', 'closed', 'open');
	},
	init: function() {
		document.querySelector('#facility_toggle_buttons').addEventListener('click', e => this.tabClick(e));
		this.mobileToggle.addEventListener('click', () => this.mobileClick());
		AutomatitCarousel({
			element: document.querySelector('#facility_caro'),
			images: window.facCaroImgs,
			imagesAsBackgrounds: true,
			useChevrons: false,
			showDots: true
		});
	}
};

const facilityFilters = {
	filters: document.querySelectorAll('#facility_filters_buttons button'),
	rows: document.querySelectorAll('.rates_row'),
	filterClick: function(e) {
		const clickedButton = e.target.closest('button');

		if(clickedButton) {
			this.filters.forEach(filter => filter.removeAttribute('active'));
			clickedButton.setAttribute('active', true);

			const activeFilter = document.querySelector('#facility_filters_buttons button[active]');

			this.rows.forEach(row => {
				row.setAttribute('hide', true);

				if(activeFilter && (row.getAttribute('size') === activeFilter.getAttribute('size') || activeFilter.getAttribute('size') === 'all')) {
					row.removeAttribute('hide');
				}
			});
		}
	},
	init: function() {
		document.querySelector('#facility_filters_buttons').addEventListener('click', e => this.filterClick(e));
	}
};

const facilityTabs = {
	buttonClick: function(e) {
		const clickedButton = e.target.closest('button');

		if(clickedButton) {
			clickedButton.switchAttribute('state', 'closed', 'open');
			document.querySelector(`.inc_about_content[tab="${clickedButton.getAttribute('tab')}"]`).switchAttribute('state', 'closed', 'open');
		}
	},
	init: function() {
		document.querySelector('#inc_about').addEventListener('click', e => this.buttonClick(e));
	}
};

const facilityNearbyMap = {
	theMap: {
		el: document.querySelector('#facility_nearby_map'),
		instance: null
	},
	init: async function() {
		const mapsHelper = getMapsHelper();

		await mapsHelper.ready();

		this.theMap.instance = mapsHelper.createMap({
			element: this.theMap.el,
			locationElementSelector: '.nearby_fac',
			useRichmarker: true,
			markerReducer: el => {
				return {
					lat: el.getAttribute('lat'),
					lng: el.getAttribute('lng'),
					content: `<a href="${el.getAttribute('link')}" class="map_pin"><img src="/dist/images/icons/ic_map_pin.svg" alt="" /></a>`
				};
			}
		});

		if(document.querySelectorAll('.nearby_fac').length === 1) {
			google.maps.event.addListenerOnce(this.theMap.instance, 'idle', () => {
				this.theMap.instance.setZoom(15);
			});
		}
	}
};

export const initFacilityTop = () => facilityTop.init();
export const initFilters = () => facilityFilters.init();
export const initFacilityTabs = () => facilityTabs.init();
export const initFacilityNearby = () => facilityNearbyMap.init();