import './polyfills';

import { initMobileNav, initDoubleClick } from './header';
import { initFacilityTop, initFilters, initFacilityTabs, initFacilityNearby } from './facility';
import { initHomeMap } from './home';
import { initFaqsTabs } from './faqs';
import { contactSubmit } from './contact.js';
import { initMultiMap } from './multiMap.js';

initMobileNav();
initDoubleClick();

if(document.querySelector('#facility_top')) {
	initFacilityTop();
}

if(document.querySelector('#facility_filters')) {
	initFilters();
}

if(document.querySelector('#inc_about')) {
	initFacilityTabs();
}

if(document.querySelector('#facility_nearby')) {
	initFacilityNearby();
}

if(document.querySelector('#home_map')) {
	initHomeMap();
}

if(document.querySelector('#faq_list')) {
	initFaqsTabs();
}

if (document.getElementById('footer_form')) {
	contactSubmit({
		elementSelector: '#footer_form',
		required: ['email_address']
	});
}

if (document.getElementById('contact_form')) {
	contactSubmit({
		required: ['name', 'email', 'phone', 'facility', 'message']
	});
}

if(document.querySelector('#contact_map')) {
	initMultiMap();
}
